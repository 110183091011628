import React, { FunctionComponent, useState } from "react";

import { ReactComponent as DashboardIc } from "../../assets/images/dashboard-icon.svg";
import { ReactComponent as UsersIc } from "../../assets/images/users-icon.svg";
import { ReactComponent as InvestmentsIc } from "../../assets/images/chartBarV-icon.svg";
import { ReactComponent as ReportsIc } from "../../assets/images/file-icon.svg";
import { ReactComponent as ListIc } from "../../assets/images/list-icon.svg";
import { ReactComponent as HammerIc } from "../../assets/images/hammer-icon.svg";
import { ReactComponent as PersonIc } from "../../assets/images/person-icon.svg";
import { ReactComponent as QuestionIc } from "../../assets/images/question-circle-icon.svg";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type AdviserPortalNavItem = {
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>> | FunctionComponent<Omit<FontAwesomeIconProps, "icon">>;
  label: string;
  route: string;
  active?: boolean;
};

interface IAdviserContext {
  navItems: Array<AdviserPortalNavItem>;
  setActiveNavItem?: (stepIndex: number) => void;
}

export const defaultNavItems: Array<AdviserPortalNavItem> = [
  {
    icon: DashboardIc,
    label: "Dashboard",
    active: true,
    route: "dashboard",
  },
  {
    icon: UsersIc,
    label: "My clients",
    route: "my-clients",
  },
  {
    icon: InvestmentsIc,
    label: "Investments",
    route: "investments",
  },
  {
    icon: ReportsIc,
    label: "Reports",
    route: "reports",
  },
  {
    icon: ListIc,
    label: "Forms and facts",
    route: "forms-facts",
  },
  {
    icon: HammerIc,
    label: "My tools",
    route: "my-tools",
  },
  {
    icon: PersonIc,
    label: "My profile",
    route: "my-profile",
  },
  {
    icon: QuestionIc,
    label: "Client FAQs",
    route: "faq",
  },
];

export const AdviserContext = React.createContext<IAdviserContext>({
  navItems: defaultNavItems,
});

const AdviserNavProvider: React.FC = ({ children }) => {
  const [navItems, setNavItems] = useState<AdviserPortalNavItem[]>(defaultNavItems);

  const setActiveNavItem = (stepIndex: number) => {
    setNavItems((prev) =>
      prev.map((el, index) => ({
        ...el,
        active: index === stepIndex,
      }))
    );
  };

  return (
    <AdviserContext.Provider
      value={{
        navItems,
        setActiveNavItem,
      }}
    >
      {children}
    </AdviserContext.Provider>
  );
};

export default AdviserNavProvider;
